import React from 'react'
import { Link, graphql } from 'gatsby'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import Pagination from '../components/pagination'

const BlogIndex = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const {
    hasNextPage,
    hasPrevPage,
    prevPagePath,
    nextPagePath
  } = pageContext

  return (
    <DefaultLayout>
      <SEO
        title={siteTitle}
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <div className="content-box clearfix">
        <div className='content-box--inner'>
          {posts.map(({ node }) => {
            return (
              <article className="post" key={node.fields.slug}>
                <div className="post-img">
                  <div aria-hidden={true}></div>
                  <img aria-hidden={true} src={node.frontmatter.banner.childImageSharp.fluid.src} alt=""/>
                </div>
                <div className="post-content">
                  <header>
                    <h2 className="post-content--title">
                      <Link className="hover-link" to={node.fields.slug}>{node.frontmatter.title}</Link>
                    </h2>
                    <span className="post-content--date">
                      {node.frontmatter.date}
                    </span>
                  </header>
                  <p>{node.excerpt}</p>
                </div>
              </article>
            )
          })}
          <Pagination hasNextPage={hasNextPage}
                      hasPrevPage={hasPrevPage}
                      nextPagePath={nextPagePath}
                      prevPagePath={prevPagePath}
          />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $postsLimit
      skip: $postsOffset
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMM, YYYY")
            title
            banner {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
