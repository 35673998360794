import React from 'react'
import { Link } from 'gatsby'
import PAGINATION from '../constans/pagination'


const Pagination = ({ hasNextPage, hasPrevPage, prevPagePath, nextPagePath  }) => {

  const linkPrev = hasPrevPage ? 'pagination-module__prev' : `pagination-module__prev disable`
  const linkNext = hasNextPage ? 'pagination-module__next' : `pagination-module__next disable`

  return (
    <div className="pagination-module">
      <Link to={hasPrevPage ? prevPagePath : '/'} rel="prev" className={linkPrev}>
        {PAGINATION.PREV_PAGE}
      </Link>

      <Link to={hasNextPage ? nextPagePath : '/'} rel="next" className={linkNext}>
        {PAGINATION.NEXT_PAGE}
      </Link>
    </div>
  )
}

export default Pagination